import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDAPAsheSt0HuR4y787CV6Kx3F74w-AmZI",
  authDomain: "helloo-e6fb4.firebaseapp.com",
  projectId: "helloo-e6fb4",
  storageBucket: "helloo-e6fb4.appspot.com",
  messagingSenderId: "468603542850",
  appId: "1:468603542850:web:da727d56aa97eb7707fcdb",
  measurementId: "G-NQ0TVJBV70"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const provider = new firebase.auth.GoogleAuthProvider();
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, provider };
export default db;
