import React, { useState } from "react";
import "./Payment.css";
import Swal from "sweetalert2";
import { useStateValue } from "../StateProvider";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import successImg from "../assets/success.svg";

function Payment() {
  const [{ user, cart }] = useStateValue();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    quantity: "",
    item: "",
  });
  const [succeeded, setSucceeded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await emailjs.send(
        SERVICE_ID, TEMPLATE_ID,formData,
        // {
        //   ...formData,
        //   cart: JSON.stringify(cart), // Include the cart details in the email
        // },
        PUBLIC_KEY
      );

      if (response.status === 200) {
        setSucceeded(true);
        setShowSuccess(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          quantity: "",
          item: "",
        });

        // Display success alert
        Swal.fire({
          icon: "success",
          title: "Order Submitted Successfully!",
          text: "We'll respond via email with details.",
        });
      } else {
        // Display error alert
        Swal.fire({
          icon: "error",
          title: "Failed to Submit Order",
          text: "Please try again later.",
        });
      }
    } catch (error) {
      // Display error alert
      Swal.fire({
        icon: "error",
        title: "Failed to Submit Order",
        text: "Please try again later.",
      });
    }
  };

  return (
    <div className={`payment ${succeeded ? "payment__success" : ""}`}>
      <h4>Complete your Order, {user?.displayName.split(" ", 1)}!</h4>
      {cart.map((item) => (
        <div key={item.id} className="payment__product">
          <p>You have ordered {item.quantity} quantity of {item.name}.</p>
        </div>
      ))}
      {succeeded && showSuccess && (
        <div className="payment__successMessage">
          <img src={successImg} alt="Success" />
          <p>Your order was successfully. We'll respond via email with details.</p>
        </div>
      )}
      {!succeeded && (
        <form className="payment__form" onSubmit={handleSubmit}>
          <div className="payment__formGroup">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="payment__formGroup">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="payment__formGroup">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="e.g., 0712345678"
              pattern="07[0-9]{8}|011[0-9]{8}"
              required
            />
          </div>
          <input type="hidden" name="quantity" value={cart.reduce((total, item) => total + item.quantity, 0)} />
          <input type="hidden" name="item" value={cart.map(item => `${item.name} (${item.quantity})`).join(", ")} />
          <button type="submit" className="payment__submitButton">
            Submit Order
          </button>
        </form>
      )}
    </div>
  );
}

export default Payment;
